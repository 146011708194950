import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Place } from 'src/app/services/place-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.scss'],
})
export class PlaceCardComponent implements OnInit {

  @Input() place: Place;
  @Input() status: string;
  @Input() scrollObservable: Observable<any>;
  @Input() extraParams: any = {};
  @Input() color = 'white';
  @Input() showStatus: boolean;
  @Input() showEdit: boolean;
  @Output() onPlaceEventTouched: EventEmitter<Place> = new EventEmitter<Place>();
  @Output() onEditEvent: EventEmitter<Place> = new EventEmitter<Place>();

  constructor() { }

  ngOnInit() {
  }

  onPlaceTouched() {
    this.onPlaceEventTouched.emit(this.place);
  }

  onEditPlaceTouched() {
    this.onEditEvent.emit(this.place);
  }

  getStatusColor() {
    if (this.status === 'Pending' || this.status === 'Update Pending') {
      return 'warning';
    } else if (this.status === 'Approved') {
      return 'success';
    } else if (this.status === 'Rejected') {
      return 'danger';
    }
  }
}
