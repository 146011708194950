export const environment = {
  production: false,
  serverUrl: 'https://admin.wai.re/api',
  appUrl: 'https://staging.wai.re',
  appImageUrl: 'https://waire.app/assets/img/og-image-waire-1200x630.png',
  appId: 'voCa5oJe5u',
  fbId: '321807022209340',
  googleMapsApiKey: 'AIzaSyCkiALSj7Jw5sIeTZIyb2sDsG-gb9Nw9rI',
  androidHeaderColor: '#d82c6b',
  defaultUnit: 'km',
  defaultLang: 'fr'
};

import 'zone.js/dist/zone-error';  // Included with Angular CLI.
