import { Injectable } from '@angular/core';
import * as Parse from 'parse';

export interface UserPlace {
  place: Place;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class Place extends Parse.Object {

  constructor() {
    super('Place');
  }

  static getInstance() {
    return this;
  }

  getSlug(): string {
    let slug = '1/home/places/' + this.id;

    if (this.slug) {
      slug += '/' + this.slug;
    }

    return slug;
  }

  static sortPlaces(places: Place[], params: any = {}) {

    const resultset1 = [];
    const resultset2 = [];
    const resultset3 = [];

    const resultset11 = [];
    const resultset22 = [];
    const resultset33 = [];

    for (const place of places) {

      if (!params.equipments && !params.ageMin && !params.ageMax && !params.accessible) {
        if(place.verified == false){
          resultset2.push(place);
          resultset22.push(place);
        }else{
          resultset1.push(place);
          resultset11.push(place);
        }
        continue;
      }

      if (params.equipments) {

        let equipments = params.equipments;

        if (typeof equipments === 'string') {
          equipments = [equipments]
        }

        const ids: string[] = place.equipments.map(
          (equipment: any) => equipment.equipment.id
        );

        const equipmentsFound = equipments
          .reduce((sum: any, i: any) => sum + ids.includes(i), 0);

        if (!place.equipments.length) {
          resultset2.push(place);
        } else if (equipmentsFound !== equipments.length) {
          resultset3.push(place);
        } else {
          resultset1.push(place);
        }

      } else {
        resultset1.push(place);
      }

      if (params.ageMin && !params.ageMax) {

        if (place.ageMin === '' && place.ageMax === '') {
          resultset22.push(place);
        } else if (
          place.ageMin && place.ageMax &&
          Number(place.ageMax) >= Number(params.ageMin) && Number(params.ageMin) >= Number(place.ageMin)) {
          resultset11.push(place);
        } else if (
          place.ageMin && !place.ageMax &&
          Number(params.ageMin) >= Number(place.ageMin)) {
          resultset11.push(place);
        } else if (
          !place.ageMin && place.ageMax &&
          Number(params.ageMin) <= Number(place.ageMax)) {
          resultset11.push(place);
        } else {
          resultset33.push(place);
        }

      } else if (!params.ageMin && params.ageMax) {

        if (place.ageMin === '' && place.ageMax === '') {
          resultset22.push(place);
        } else if (
          place.ageMin && place.ageMax &&
          Number(place.ageMax) >= Number(params.ageMax) && Number(params.ageMax) >= Number(place.ageMin)) {
          resultset11.push(place);
        } else if (
          place.ageMin && !place.ageMax &&
          Number(params.ageMax) >= Number(place.ageMin)) {
          resultset11.push(place);
        } else if (
          !place.ageMin && place.ageMax &&
          Number(params.ageMax) <= Number(place.ageMax)) {
          resultset11.push(place);
        } else {
          resultset33.push(place);
        }

      } else if (params.ageMin && params.ageMax) {

        if (place.ageMin === '' && place.ageMax === '') {
          resultset22.push(place);
        } else if (
          place.ageMin && place.ageMax &&
          (Number(place.ageMin) <= Number(params.ageMin) && Number(params.ageMin) <= Number(place.ageMax)) &&
          (Number(params.ageMax) <= Number(place.ageMax))) {
          resultset11.push(place);
        } else if (place.ageMin && !place.ageMax &&
          Number(params.ageMin) >= Number(place.ageMin) &&
          Number(params.ageMax) >= Number(place.ageMin)) {
          resultset11.push(place);
        } else if (!place.ageMin && place.ageMax &&
          Number(params.ageMin) <= Number(place.ageMax) &&
          Number(params.ageMax) <= Number(place.ageMax)) {
          resultset11.push(place);
        }  else {
          resultset33.push(place);
        }
      } else {
        resultset11.push(place);
      }

    }

    //console.log('green1', resultset1.map(i => i.title));
    //console.log('grey1', resultset2.map(i => i.title));
    //console.log('red3', resultset3.map(i => i.title));

    //console.log('----')

    //console.log('green2', resultset11.map(i => i.title));
    //console.log('grey2', resultset22.map(i => i.title));
    //console.log('red3', resultset33.map(i => i.title));

    //console.log('----')

    let green = [];

    if (resultset1.length && resultset11.length) {
      green = resultset1.filter(e => {
        return resultset11.some(item => item.id === e.id);
      });
    } else if (resultset1.length && (!params.ageMin && !params.ageMax)) {
      green = resultset1;
    } else if (resultset11.length && !params.equipments) {
      green = resultset11;
    }

    if (params.accessible) {
      green = green.filter(place => place.isAccessible);
    }

    let grey = [];

    if (resultset2.length && resultset22.length) {
      grey = resultset2.filter(e => {
        return resultset22.some(item => item.id === e.id);
      });
    } else if (resultset2.length && (!params.ageMin && !params.ageMax)) {
      grey = resultset2;
    } else if (resultset22.length && !params.equipments) {
      grey = resultset22;
    }

    const red = places.filter(result => {
      return !(green.find(result1 => result.id === result1.id) ||
        grey.find(result1 => result.id === result1.id))
    });

    //console.log('green', green.map(i => i.title));
    //console.log('grey', grey.map(i => i.title));
    //console.log('red', red.map(i => i.title));

    return { green, grey, red };

  }

  distance(location: any, unit: string = 'km', formatted: boolean = true) {
    if (!location) return null;

    var geoPoint = new Parse.GeoPoint({
      latitude: location.latitude,
      longitude: location.longitude
    });

    if (formatted) {
      if (unit === 'km') {
        return this.location.kilometersTo(geoPoint).toFixed(1) + ' ' + unit;
      } else {
        return this.location.milesTo(geoPoint).toFixed(1) + ' ' + unit;
      }
    } else {
      if (unit === 'km') {
        return this.location.kilometersTo(geoPoint);
      } else {
        return this.location.milesTo(geoPoint);
      }
    }


  }

  like(place: Place) {
    return Parse.Cloud.run('likePlace', { placeId: place.id });
  }

  isLiked(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceLiked', { placeId: place.id });
  }

  isStarred(place: Place): Promise<boolean> {
    return Parse.Cloud.run('isPlaceStarred', { placeId: place.id });
  }

  loadOne(id: string): Promise<Place> {
    const query = new Parse.Query(Place);
    query.containedIn('status', ['Approved', 'Update Pending']);
    query.include(['category', 'equipments.equipment']);
    return query.get(id);
  }

  load(params: any = {}): Promise<Place[]> {

    const page = params.page || 0;
    const limit = params.limit || 100;
    const distance = params.distance || 25;
    const status = params.status || ['Approved', 'Update Pending'];

    const query = new Parse.Query(Place);

    if (Array.isArray(status)) {
      query.containedIn('status', status);
    } else {
      query.equalTo('status', status);
    }

    if (params.category) {
      query.equalTo('category', params.category);
    }

    if (params.featured) {
      query.equalTo('isFeatured', true);
    }

    if (params.user) {
      query.equalTo('user', params.user);
    }

    if (params.canonical && params.canonical !== '') {
      query.contains('canonical', params.canonical);
    }

    if (params.bounds) {

      const southwest = new Parse.GeoPoint(
        params.bounds[0].latitude,
        params.bounds[0].longitude
      );

      const northeast = new Parse.GeoPoint(
        params.bounds[1].latitude,
        params.bounds[1].longitude
      );

      query.withinGeoBox('location', southwest, northeast);

    } else if (params.lat && params.lng) {

      const point = new Parse.GeoPoint({
        latitude: Number(params.lat),
        longitude: Number(params.lng)
      });

      const sorted = true;

      if (params.unit && params.unit === 'km') {
        // @ts-ignore
        query.withinKilometers('location',point,distance,sorted);
      } else {
        // @ts-ignore
        query.withinMiles('location',point,distance,sorted);
      }

    } else {
      query.descending('createdAt');
    }

    /* if (params.accessible === 'true') {
      query.equalTo('isAccessible', true);
    } */

    query.skip(page * limit);
    query.limit(limit);

    query.include('category');
    query.doesNotExist('deletedAt');

    return query.find();
  }

  loadUserPlaces(params: any = {}): Promise<UserPlace[]> {
    return Parse.Cloud.run('getUserPlaces', params);
  }

  loadFavorites(params: any = {}): Promise<Place[]> {

    const page = params.page || 0;
    const limit = params.limit || 100;

    const query = new Parse.Query(Place);
    query.containedIn('status', ['Approved', 'Update Pending']);
    query.equalTo('likes', Parse.User.current());

    query.skip(page * limit);
    query.limit(limit);
    query.include('category');
    query.doesNotExist('deletedAt');

    return query.find();
  }

  get title(): string {
    return this.get('title');
  }

  set title(val) {
    this.set('title', val);
  }

  get description(): string {
    return this.get('description');
  }

  set description(val) {
    this.set('description', val);
  }

  get phone(): string {
    return this.get('phone');
  }

  set phone(val) {
    this.set('phone', val);
  }

  get website(): string {
    return this.get('website');
  }

  set website(val) {
    this.set('website', val);
  }

  get address(): string {
    return this.get('address');
  }
/*
  set address(val) {
    this.set('address', val);
  }
*/
  get category() {
    return this.get('category');
  }

  set category(val) {
    this.set('category', val);
  }

  get image() {
    return this.get('image');
  }

  set image(val) {
    this.set('image', val);
  }

  get images() {
    return this.get('images');
  }

  set images(val) {
    this.set('images', val);
  }

  get location() {
    return this.get('location');
  }

  set location(val) {
    var geoPoint = new Parse.GeoPoint({
      latitude: val.lat,
      longitude: val.lng
    });
    this.set('location', geoPoint);
  }

  get imageTwo() {
    return this.get('imageTwo');
  }

  get imageThree() {
    return this.get('imageThree');
  }

  get imageFour() {
    return this.get('imageFour');
  }

  get imageThumb() {
    return this.get('imageThumb');
  }

  get imgURL(){
    return this.get('imgURL');
  }

  get thumbURL(){
    return this.get('thumbURL');
  }

  get galleryURLs(){
    return this.get('galleryURLs');
  }

  get ratingCount() {
    return this.get('ratingCount');
  }

  get ratingTotal() {
    return this.get('ratingTotal');
  }

  get status() {
    return this.get('status');
  }

  get facebook() {
    return this.get('facebook');
  }

  get youtube() {
    return this.get('youtube');
  }

  get instagram() {
    return this.get('instagram');
  }

  get longDescription() {
    return this.get('longDescription');
  }

  get slug() {
    return this.get('slug');
  }

  get rating() {
    if (!this.ratingCount && !this.ratingTotal) return 0;
    return Math.round(this.ratingTotal / this.ratingCount);
  }

  set equipments(val: any) {
    this.set('equipments', val);
  }

  get equipments() {
    return this.get('equipments');
  }

  get ageMin(): any {
    return this.get('ageMin');
  }

  get ageMax(): any {
    return this.get('ageMax');
  }

  set isAccessible(val: boolean) {
    this.set('isAccessible', val);
  }

  get isAccessible() {
    return this.get('isAccessible');
  }

  get user() {
    return this.get('user');
  }

  get lastModifiedBy() {
    return this.get('lastModifiedBy');
  }

  get verified() {
    return this.get('verified');
  }

  set verified(val) {
    this.set('verified', val);
  }

}

Parse.Object.registerSubclass('Place', Place);
