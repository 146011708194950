import { Injectable } from "@angular/core";
import * as Parse from "parse";

@Injectable({
  providedIn: "root"
})
export class Equipment extends Parse.Object {
  constructor() {
    super("Equipment");
  }

  static getInstance() {
    return this;
  }

  load(params: any = {}): Promise<Equipment[]> {
    const query = new Parse.Query(Equipment);

    if (params.category) {
      query.equalTo('category', params.category);
    }

    if (params.categoryStatus) {
      const innerQuery = new Parse.Query('Category');
      innerQuery.equalTo('status', 'Active');
      innerQuery.doesNotExist('deletedAt');
      query.matchesQuery('category', innerQuery);
    }

    query.equalTo("status", "Active");
    query.ascending("sort");
    query.doesNotExist("deletedAt");
    return query.find();
  }

  get name(): any {
    return this.get("name");
  }

  get image() {
    return this.get("image");
  }

  get imageThumb() {
    return this.get("imageThumb");
  }
}

Parse.Object.registerSubclass("Equipment", Equipment);
